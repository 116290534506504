.project-stage {
  background-color: #fff;
  width:100%;
  padding:16px;
  margin-top: 16px;
  border-radius: 8px;
  box-shadow:0px 0px 4px 0px #E5E5E5;
}

.project-stage-inner{
  padding: "16px";
}

h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.stage-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.stage-list::before {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #e0e0e0;
}

.stage {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  position: relative;
}

.stage-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-right: 15px;
  z-index: 1;
}

.stage.completed .stage-icon {
  background-color: #008080;
  border-color: #008080;
  color: white;
}

.stage.current .stage-icon {
  border-color: #008080;
  color: #008080;
}

.stage-name {
  font-size: 16px;
  color: #5E5E5E;
}

.nomargin{
  margin-bottom: 0px;
}